import { StyledSvg } from "baseui/icon";
import React from "react";

export const AddFileIcon = (props: React.ComponentProps<typeof StyledSvg>) => (
  <StyledSvg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6851 5.57707C12.4502 5.23205 12.106 4.84171 11.67 4.35638C11.5489 4.22103 11.4241 4.08141 11.3012 3.94181L11.301 3.94152C11.2072 3.8367 11.1158 3.73074 11.0246 3.62503L11.0246 3.62501L11.0245 3.62497L10.9343 3.52051C10.5072 3.02533 10.1636 2.63417 9.85968 2.36712C9.55612 2.10034 9.28418 1.95 9.00001 1.95H5.00001C3.86503 1.95 2.95001 2.99928 2.95001 4.27219V11.7278C2.95001 13.0007 3.86503 14.05 5.00001 14.05H7.5V12.8139H5.00001C4.4807 12.8139 4.05001 12.3343 4.05001 11.7278V4.27219C4.05001 3.66566 4.4807 3.18609 5.00001 3.18609L8.63726 3.18388C8.78755 3.23023 8.86739 3.3587 8.90891 3.55051C8.95008 3.74074 8.95005 3.97844 8.95001 4.22497L8.95001 4.24556V5.97633C8.95001 6.31063 9.1912 6.59438 9.50001 6.59438H11C11.252 6.59438 11.4917 6.59562 11.669 6.66296C11.7559 6.69596 11.8249 6.74388 11.8726 6.81328C11.9204 6.88275 11.95 6.97839 11.95 7.11242V7.5H13.05V6.54438C13.05 6.22815 12.9203 5.92247 12.6851 5.57707ZM12.775 9C12.9959 9 13.175 9.17909 13.175 9.4V11.825L15.6 11.825C15.8209 11.825 16 12.0041 16 12.225V12.775C16 12.9959 15.8209 13.175 15.6 13.175H13.175V15.6C13.175 15.8209 12.9959 16 12.775 16H12.225C12.0041 16 11.825 15.8209 11.825 15.6V13.175H9.4C9.17909 13.175 9 12.9959 9 12.775V12.225C9 12.0041 9.17909 11.825 9.4 11.825H11.825V9.4C11.825 9.17909 12.0041 9 12.225 9H12.775Z"
    />
  </StyledSvg>
);

import { Row, expandBorderRadii, marginHorizontal, paddingUtil } from "@gadgetinc/widgets";
import { ErrorIcon } from "@gadgetinc/widgets/src/icons/ErrorIcon";
import { GGTIcon } from "@gadgetinc/widgets/src/icons/GGTIcon";
import { WarningIcon } from "@gadgetinc/widgets/src/icons/WarningIcon";
import { useStyletron } from "baseui";
import { Button, KIND } from "baseui/button";
import { ParagraphSmall } from "baseui/typography";
import React from "react";

/**
 * Bar across the bottom of the workspace in the Gadget editor with controls for problems, connection status, deploy button, etc
 */
export const StatusBar = () => {
  const [css, $theme] = useStyletron();

  const border = {
    width: "1px",
    style: "solid",
    color: $theme.colors.borderOpaque,
  } as const;

  return (
    <footer
      className={css({
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        height: $theme.sizing.scale900,
        width: "100%",

        position: "relative",
      })}
    >
      <Row $gap={$theme.sizing.scale600} $style={{ ...marginHorizontal($theme.sizing.scale600) }}>
        <GGTIcon style={{ width: $theme.sizing.scale600, height: $theme.sizing.scale600 }} />
      </Row>
      <div>
        <Button
          onClick={() => undefined}
          kind={KIND.tertiary}
          overrides={{
            BaseButton: {
              style: ({ $theme }) => ({
                ...expandBorderRadii("0px"),
                ...paddingUtil($theme.sizing.scale200, $theme.sizing.scale500),
                display: "flex",
                gap: $theme.sizing.scale400,
                height: "100%",
                marginRight: "4px",
              }),
            },
          }}
        >
          <>
            <Row
              $style={{
                alignItems: "center",
                justifyContent: "center",
                columnGap: $theme.sizing.scale200,
              }}
            >
              <ErrorIcon $disabled={true} $size={16} />
              <ParagraphSmall>0</ParagraphSmall>
            </Row>
            <Row
              $style={{
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "auto",
                columnGap: $theme.sizing.scale200,
              }}
            >
              <WarningIcon $disabled={true} $size={16} />
              <ParagraphSmall>0</ParagraphSmall>
            </Row>
          </>
        </Button>
      </div>
    </footer>
  );
};

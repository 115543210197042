import { AccordionExpandIcon } from "@gadgetinc/widgets/src/icons/AccordionExpandIcon";
import { HTMLIcon } from "@gadgetinc/widgets/src/icons/HTMLIcon";
import { JavaScriptIcon } from "@gadgetinc/widgets/src/icons/JavaScriptIcon";
import { NpmIcon } from "@gadgetinc/widgets/src/icons/NpmIcon";
import React from "react";

export const FileIcon = (props: { path: string; extension?: string; isDirectory?: boolean }) => {
  if (props.isDirectory) {
    return <AccordionExpandIcon key="icon" direction="right" />;
  } else {
    switch (props.extension) {
      case "html":
        return <HTMLIcon />;
      case "js":
        return <JavaScriptIcon />;
      default:
        return <NpmIcon />;
    }
  }
};

import { Column, expandBaseWebBorder, expandBorderRadii } from "@gadgetinc/widgets";
import { useStyletron } from "baseui";
import type { ComponentProps } from "react";
import React from "react";

export const WorkspaceCard = (props: ComponentProps<typeof Column>) => {
  const [_css, $theme] = useStyletron();
  return (
    <Column
      {...props}
      $style={{
        flex: 1,
        height: "100%",
        overflow: "hidden",
        ...expandBaseWebBorder($theme.borders.border400),
        ...expandBorderRadii($theme.borders.radius500),
        boxShadow: $theme.lighting.depth2,
        backgroundColor: $theme.colors.background,
        ...props.$style,
        marginBottom: $theme.sizing.scale300,
      }}
    />
  );
};

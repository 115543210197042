import { createContext, useContext } from "react";

export const LeftNavContext = createContext<{
  scrollTop: number;
  scrollToOffset: (offset: number, behavior?: ScrollBehavior, center?: boolean) => void;
  scrollIntoView: (element: HTMLElement | HTMLDivElement, behavior?: ScrollBehavior, center?: boolean) => void;
}>({
  scrollTop: 0,
  scrollToOffset: () => undefined,
  scrollIntoView: () => undefined,
});
export const useLeftNav = () => useContext(LeftNavContext);

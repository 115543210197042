import { StyledSvg } from "baseui/icon";
import React from "react";

export const HomeIcon = (props: React.ComponentProps<typeof StyledSvg> & { filled: boolean }) => {
  if (props.filled) return HomeFilledIcon(props);
  return HomeOutlinedIcon(props);
};

export const HomeOutlinedIcon = (props: React.ComponentProps<typeof StyledSvg>) => (
  <StyledSvg viewBox="0 0 16 16" {...props}>
    <path d="M7.98372 1.33423C7.87711 1.33787 7.77445 1.37551 7.69076 1.44165L2.95312 5.17407C2.35183 5.64795 2 6.37206 2 7.13761V13.5009C2 13.9554 2.3788 14.3342 2.83333 14.3342H6.16667C6.6212 14.3342 7 13.9554 7 13.5009V10.1676C7 10.0692 7.06832 10.0009 7.16667 10.0009H8.83333C8.93168 10.0009 9 10.0692 9 10.1676V13.5009C9 13.9554 9.3788 14.3342 9.83333 14.3342H13.1667C13.6212 14.3342 14 13.9554 14 13.5009V7.13761C14 6.37206 13.6482 5.64795 13.0469 5.17407L8.30925 1.44165C8.21678 1.36861 8.1015 1.33056 7.98372 1.33423ZM8 2.47095L12.4284 5.95988C12.7898 6.24467 13 6.67783 13 7.13761V13.3342H10V10.1676C10 9.52924 9.47165 9.00089 8.83333 9.00089H7.16667C6.52835 9.00089 6 9.52924 6 10.1676V13.3342H3V7.13761C3 6.67783 3.21025 6.24467 3.57161 5.95988L8 2.47095Z" />
  </StyledSvg>
);

export const HomeFilledIcon = (props: React.ComponentProps<typeof StyledSvg>) => (
  <StyledSvg viewBox="0 0 16 16" {...props}>
    <path d="M13.1667 14.334H10.1667C9.70633 14.334 9.33333 13.961 9.33333 13.5007V10.5007C9.33333 10.1323 9.035 9.83398 8.66667 9.83398H7.33333C6.965 9.83398 6.66667 10.1323 6.66667 10.5007V13.5007C6.66667 13.961 6.29367 14.334 5.83333 14.334H2.83333C2.373 14.334 2 13.961 2 13.5007V7.13832C2 6.37198 2.35133 5.64798 2.953 5.17398L7.69033 1.44098C7.872 1.29832 8.128 1.29832 8.30933 1.44098L13.0473 5.17398C13.649 5.64798 14 6.37165 14 7.13765V13.5007C14 13.961 13.627 14.334 13.1667 14.334Z" />
  </StyledSvg>
);

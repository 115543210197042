import { useCurrentEnvironment } from "../workspace/EnvironmentContext";

export function useSlugifiedExternalHref(href: undefined): undefined;
export function useSlugifiedExternalHref(href: string): string;
export function useSlugifiedExternalHref(href: string | undefined): string | undefined;
export function useSlugifiedExternalHref(href: string | undefined) {
  const root = useCurrentEnvironment();
  // support being used outside the edit context
  if (!root) return href;

  const settings = root.settings.getOrSuspend();

  if (!href) return href;

  if (href.startsWith(window.GadgetEditConfig.docsRootURL + "/guides")) {
    const url = new URL(href);
    url.searchParams.set("slug", settings.applicationSlug);
    href = url.toString();
  }
  return href;
}

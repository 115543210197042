import { Column, expandBorderRadii } from "@gadgetinc/widgets";
import { useStyletron } from "baseui";
import React from "react";
import { EntryBox } from "../../edit/workspace/LeftNavBar/EntryBox";
import { EntryLink } from "../../edit/workspace/LeftNavBar/EntryLink";
import { ColorBlob } from "./ColorBlob";

export const Models = (props: { apiIdentifier: string }) => {
  const [css, $theme] = useStyletron();
  return (
    <Column $style={expandBorderRadii($theme.borders.radius200)}>
      <header className={css({ position: "relative", zIndex: 1 })}>
        <EntryBox>
          <EntryLink selected={false} href="#" onClick={() => undefined}>
            <span
              className={css({
                display: "inline-block",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: "100%",
              })}
            >
              <ColorBlob
                color={$theme.colors.primary400}
                $style={{ marginLeft: $theme.sizing.scale100, marginRight: $theme.sizing.scale400 }}
              />
              {props.apiIdentifier}
            </span>
          </EntryLink>
        </EntryBox>
      </header>
    </Column>
  );
};

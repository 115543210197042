import { Column, Row, ToolTip, expandBorderTop } from "@gadgetinc/widgets";
import useIntersectionObserver from "@react-hook/intersection-observer";
import { useStyletron } from "baseui";
import React, { useEffect, useRef, useState } from "react";
import { useLeftNav } from "./LeftNavContext";
import { MenuFolderHeader } from "./MenuFolderHeader";

const HEADER_INNER_HEIGHT = 24;
const HEADER_PADDING = 3;
export const HEADER_HEIGHT = HEADER_PADDING * 2 + HEADER_INNER_HEIGHT;

export const NavSection = (props: {
  title: string | React.ReactNode;
  children: React.ReactNode;
  actions?: React.ReactElement[];
  $gap?: string | number;
  /** Index from the TOP down; used for determining top stickiness */
  index: number;
  /** Index from the BOTTOM up; used for determining bottom stickiness */
  bottomIndex: number;
  bottomMargin?: number;
  dataTestId?: string;
  tooltipText?: string;
  onToggleExpand?: () => void;
  expanded?: boolean;
}) => {
  const [_css, $theme] = useStyletron();
  const [topRef, setTopRef] = useState<HTMLElement | null>(null);
  const [bottomRef, setBottomRef] = useState<HTMLElement | null>(null);
  const [childRef, setChildRef] = useState<HTMLElement | null>(null);
  const [isStickyAtBottom, setIsStickyAtBottom] = useState(false);
  const [isHighlighted, setIsHighlighted] = useState(false);
  const timer = useRef<ReturnType<typeof setTimeout>>();
  const { scrollTop, scrollToOffset } = useLeftNav();

  const top = props.index * HEADER_HEIGHT;
  const bottom = props.bottomIndex * HEADER_HEIGHT;
  const rootBottom = bottom + 1;

  const { isIntersecting: isTopIntersecting, intersectionRect } = useIntersectionObserver(topRef, {
    threshold: 1,
    initialIsIntersecting: true,
  });
  const { isIntersecting: isBottomIntersecting } = useIntersectionObserver(bottomRef, {
    threshold: 1,
    rootMargin: `0px 0px -${rootBottom}px`,
    initialIsIntersecting: true,
  });
  const intersectionTop = intersectionRect?.top ?? 0;
  const isAtTop = intersectionTop <= scrollTop;
  const isStickyAtTop = isAtTop && !isTopIntersecting;
  const isSticky = isStickyAtTop || isStickyAtBottom;

  const boxShadow = isStickyAtTop ? `0 2px 0 ${$theme.colors.alpha50}` : undefined;
  const backgroundColor = isHighlighted ? $theme.colors.electricBlue100 : $theme.colors.primary50;
  const isFirst = props.index === 0;
  const hasTopDivider = isSticky && !isFirst;
  const color = isHighlighted ? $theme.colors.contentPrimary : $theme.colors.contentSecondary;
  const transition = isSticky ? "none" : "background-color .2s, color .2s";
  const rowStyle = {
    alignItems: "center",
    justifyContent: "space-between",
    boxSizing: "content-box",
    height: `${HEADER_INNER_HEIGHT}px`,
    paddingBottom: `${HEADER_PADDING}px`,
    paddingTop: `${HEADER_PADDING}px`,
    paddingRight: $theme.sizing.scale400,
    width: "100%",
  } as const;

  const onClick = () => {
    if (props.onToggleExpand) props.onToggleExpand();
  };

  const headerTitle = (
    <MenuFolderHeader onClick={onClick} expanded={props.expanded}>
      {props.title}
    </MenuFolderHeader>
  );
  const headerInnerContent = !isSticky ? (
    headerTitle
  ) : (
    <ToolTip
      ignoreBoundary
      placement="right"
      labelSize="medium"
      label={`View ${props.tooltipText ?? props.title}`}
      overrides={{
        Outer: {
          style: {
            width: "100%",
          },
        },
        Inner: {
          style: {
            ...rowStyle,
            display: "flex",
          },
        },
      }}
    >
      {headerTitle}
    </ToolTip>
  );
  const headerContent = (
    <Row $style={rowStyle}>
      {headerInnerContent}
      {props.actions && (
        <Row $gap={$theme.sizing.scale200} $style={{ color: $theme.colors.contentPrimary }}>
          {props.actions}
        </Row>
      )}
    </Row>
  );

  useEffect(() => {
    setIsStickyAtBottom(!isBottomIntersecting);
  }, [isBottomIntersecting]);

  return (
    <>
      <Row
        ref={setTopRef}
        $style={{
          height: "0px",
          top: `-${top}px`,
          position: "relative",
        }}
      />
      <Row
        ref={setBottomRef}
        $as="header"
        $style={{
          ...expandBorderTop({
            borderColor: hasTopDivider ? $theme.colors.primary200 : backgroundColor,
            borderStyle: "solid",
            borderWidth: "1px",
          }),
          backgroundColor,
          boxShadow,
          bottom: `${bottom}px`,
          boxSizing: "border-box",
          cursor: isSticky ? "pointer" : undefined,
          flexGrow: 0,
          height: `${HEADER_HEIGHT}px`,
          overflow: "hidden",
          position: "sticky",
          top: `${top}px`,
          transition: isHighlighted ? "background-color .45s, color .45s" : transition,
          zIndex: 10,
          ":hover": {
            backgroundColor: isSticky ? $theme.colors.primary50 : backgroundColor,
            color: isSticky ? $theme.colors.contentPrimary : color,
            transition: "none",
          },
        }}
      >
        {headerContent}
      </Row>
      <Column
        data-testid={props.dataTestId}
        ref={setChildRef}
        $gap={props.$gap ?? "1px"}
        $as="section"
        $style={{
          flexGrow: 0,
          display: props.expanded ? "contents" : "none",
        }}
      >
        {props.children}
      </Column>
    </>
  );
};

import { Row, expandBorderRadii } from "@gadgetinc/widgets";
import { useStyletron } from "baseui";
import { omit } from "lodash";
import type { ReactNode } from "react";
import React, { forwardRef } from "react";
import type { StyleObject } from "styletron-react";

export const EntryBox = forwardRef<
  HTMLDivElement,
  { children: ReactNode; selected?: boolean; selectionWithin?: boolean; $style?: StyleObject; $indent?: boolean }
>((props, ref) => {
  const [_css, $theme] = useStyletron();

  return (
    <Row
      ref={ref}
      $style={{
        alignItems: "center",
        position: "relative",
        ...expandBorderRadii($theme.borders.radius200),
        scrollMarginTop: $theme.sizing.scale900,
        scrollMarginBottom: $theme.sizing.scale900,
        color: $theme.colors.primary600,
        paddingLeft: props.$indent ? $theme.sizing.scale600 : undefined,
        backgroundColor: props.selected ? $theme.colors.alpha100 : undefined,
        ":hover": {
          color: $theme.colors.contentPrimary,
          backgroundColor: $theme.colors.alpha50,
        },
        ...props.$style,
      }}
      {...omit(props, ["selected", "$style", "selectionWithin"])}
    >
      {props.children}
    </Row>
  );
});

import { useStyletron } from "baseui";
import type { Theme } from "baseui/theme";
import React, { forwardRef } from "react";
import type { StyleObject } from "styletron-react";

export const getScrollableStyles = (theme: Theme) => ({
  "::-webkit-scrollbar": {
    backgroundColor: "transparent",
    width: "12px",
    height: "12px",
  },
  "::-webkit-scrollbar-thumb": {
    border: `2px solid ${theme.colors.backgroundSecondary}`,
    backgroundColor: theme.colors.backgroundQuinary,
    borderRadius: "10px",
  },
  "::-webkit-scrollbar-corner": {
    backgroundColor: "transparent",
  },
});

export const ScrollableContainer = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & { style?: StyleObject; dataTestId?: string }
>(({ style, children, className, dataTestId, ...rest }, ref) => {
  const [css, $theme] = useStyletron();
  const divStyles = style || {};

  return (
    <div
      data-testid={dataTestId}
      ref={ref}
      {...rest}
      className={
        css({
          ...getScrollableStyles($theme),
          ...divStyles,
        }) + (className ? ` ${className}` : "")
      }
    >
      {children}
    </div>
  );
});

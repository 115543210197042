import { styled } from "baseui";

export const Subheading = styled("h2", {
  fontFamily: "Inconsolata",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "140%",
  letterSpacing: "1.5px",
  textTransform: "uppercase",
  margin: 0,
});

import { OpenInNewTabIcon } from "@gadgetinc/widgets/src/icons/OpenInNewTabIcon";
import { useStyletron } from "baseui";
import { omit } from "lodash";
import type { ReactNode } from "react";
import React, { forwardRef } from "react";
import type { StyleObject } from "styletron-react";
import { useHover } from "../../../../lib/hooks";
import { useSlugifiedExternalHref } from "../../shared/external-href";

export const EntryLink = forwardRef<
  HTMLAnchorElement,
  JSX.IntrinsicElements["a"] & { selected: boolean; icon?: ReactNode; $style?: StyleObject }
>((props, ref) => {
  const [css, $theme] = useStyletron();
  const [isHovered, hoverProps] = useHover<HTMLAnchorElement>();
  const href = useSlugifiedExternalHref(props.href);

  return (
    <a
      ref={ref}
      {...omit(props, ["$style", "icon"])}
      href={href}
      onMouseEnter={(event) => {
        hoverProps.onMouseEnter(event);
        if (props.onMouseEnter) props.onMouseEnter(event);
      }}
      onMouseLeave={(event) => {
        hoverProps.onMouseLeave(event);
        if (props.onMouseLeave) props.onMouseLeave(event);
      }}
      className={css({
        textDecoration: "none",
        cursor: "pointer",
        color: props.selected ? $theme.colors.contentPrimary : $theme.colors.primary600,
        paddingTop: $theme.sizing.scale100,
        paddingBottom: $theme.sizing.scale100,
        paddingLeft: $theme.sizing.scale100,
        paddingRight: $theme.sizing.scale100,
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        gap: $theme.sizing.scale400,
        alignItems: "center",
        fontWeight: props.selected ? 600 : 500,
        ...omit($theme.typography.LabelMedium, "fontWeight"),
        outlineColor: $theme.colors.contentAccent,

        ...props.$style,
      })}
      draggable={props.draggable ?? false}
    >
      {props.icon}
      {props.children}
      {props.target == "_blank" && (
        <>
          <div className={css({ flexGrow: 1 })}></div>
          <OpenInNewTabIcon className={css({ color: $theme.colors.contentSecondary, opacity: isHovered ? 1 : 0 })} />
        </>
      )}
    </a>
  );
});

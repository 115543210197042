import { useStyletron } from "baseui";
import { StyledSvg } from "baseui/icon";
import React from "react";

export const GGTIcon = (props: React.ComponentProps<typeof StyledSvg>) => {
  const [_, $theme] = useStyletron();

  return (
    <StyledSvg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 3.3335C5.90667 3.3335 4.18399 4.95016 4.01432 7.00016H3.83333C2.271 7.00016 1 8.27116 1 9.8335C1 11.3958 2.271 12.6668 3.83333 12.6668H6.00846C6.0958 10.9958 7.474 9.66683 9.16667 9.66683H12.8333C13.6373 9.66683 14.3687 9.96907 14.9271 10.4624C14.9731 10.2601 15 10.0498 15 9.8335C15 8.27116 13.729 7.00016 12.1667 7.00016H11.9857C11.816 4.95016 10.0933 3.3335 8 3.3335ZM9.16667 10.3335C7.788 10.3335 6.66667 11.4548 6.66667 12.8335C6.66667 14.2122 7.788 15.3335 9.16667 15.3335H9.83333C10.1097 15.3335 10.3333 15.1098 10.3333 14.8335C10.3333 14.5572 10.1097 14.3335 9.83333 14.3335H9.16667C8.33967 14.3335 7.66667 13.6605 7.66667 12.8335C7.66667 12.0065 8.33967 11.3335 9.16667 11.3335H9.83333C10.1097 11.3335 10.3333 11.1098 10.3333 10.8335C10.3333 10.5572 10.1097 10.3335 9.83333 10.3335H9.16667ZM12.1667 10.3335C11.8903 10.3335 11.6667 10.5572 11.6667 10.8335C11.6667 11.1098 11.8903 11.3335 12.1667 11.3335H12.8333C13.6603 11.3335 14.3333 12.0065 14.3333 12.8335C14.3333 13.6605 13.6603 14.3335 12.8333 14.3335H12.1667C11.8903 14.3335 11.6667 14.5572 11.6667 14.8335C11.6667 15.1098 11.8903 15.3335 12.1667 15.3335H12.8333C14.212 15.3335 15.3333 14.2122 15.3333 12.8335C15.3333 11.4548 14.212 10.3335 12.8333 10.3335H12.1667ZM9.16667 12.3335C8.89033 12.3335 8.66667 12.5572 8.66667 12.8335C8.66667 13.1098 8.89033 13.3335 9.16667 13.3335H12.8333C13.1097 13.3335 13.3333 13.1098 13.3333 12.8335C13.3333 12.5572 13.1097 12.3335 12.8333 12.3335H9.16667Z"
        fill={props.color ?? $theme.colors.primary600}
      />
    </StyledSvg>
  );
};

import { expandBorderRadii } from "@gadgetinc/widgets";
import React from "react";
import { useStyletron } from "baseui";
import { omit } from "lodash";
import { Subheading } from "../../../design-system/Subheading";
import { ChevronDownIcon } from "@gadgetinc/widgets/src/icons/ChevronDownIcon";
import { ChevronIcon } from "@gadgetinc/widgets/src/icons/ChevronIcon";

export const MenuFolderHeader = (props: {
  hovered?: boolean;
  selected?: boolean;
  expanded?: boolean;
  onClick: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  children: React.ReactNode;
}) => {
  const [css, $theme] = useStyletron();

  let backgroundColor = undefined;

  if (props.hovered) backgroundColor = $theme.colors.alpha50;
  if (props.selected) backgroundColor = $theme.colors.alpha100;

  return (
    <a
      className={css({
        textDecoration: "none",
        cursor: "pointer",
        textTransform: "uppercase",
        paddingTop: $theme.sizing.scale200,
        paddingBottom: $theme.sizing.scale200,
        paddingLeft: $theme.sizing.scale300,
        paddingRight: $theme.sizing.scale300,
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        ...omit($theme.typography.LabelMedium, "fontWeight"),
        outlineColor: $theme.colors.contentAccent,
        backgroundColor,
        ...expandBorderRadii($theme.borders.radius200),
      })}
      onClick={props.onClick}
    >
      {props.expanded ? <ChevronDownIcon /> : <ChevronIcon />}
      <Subheading className={css({ paddingLeft: "8px" })}>{props.children}</Subheading>
    </a>
  );
};

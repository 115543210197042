import { Column, Row, expandPadding } from "@gadgetinc/widgets";
import { APIIcon } from "@gadgetinc/widgets/src/icons/APIIcon";
import { HomeIcon } from "@gadgetinc/widgets/src/icons/HomeIcon";
import { LogsIcon } from "@gadgetinc/widgets/src/icons/LogsIcon";
import { SettingsIcon } from "@gadgetinc/widgets/src/icons/SettingsIcon";
import { useStyletron } from "baseui";
import { colors } from "baseui/tokens";
import React from "react";
import { WorkspaceCard } from "../../edit/editor/WorkspaceCard";
import { Divider } from "../../edit/shared/Divider";
import { ScrollableContainer } from "../../edit/shared/ScrollableContainer";
import { LeftNavContext } from "../../edit/workspace/LeftNavBar/LeftNavContext";
import { NavSections } from "./NavSections";
import { StatusBar } from "./StatusBar";
import { TabStartEntryLink } from "./TabStartEntryLink";

/** Mimics a shell version of the editor, to use as a background in auth pages (pre-app creation) */
export const AuthAppBackground = () => {
  const [css, $theme] = useStyletron();
  return (
    <LeftNavContext.Provider
      value={{
        scrollTop: 0,
        scrollToOffset: () => undefined,
        scrollIntoView: () => undefined,
      }}
    >
      <article
        className={css({
          display: "grid",
          gridGap: "0px 0px",
          gridTemplateColumns: `270px 1fr`,
          overflow: "hidden",
          height: "100vh",
          width: "100vw",
        })}
      >
        <Column
          $as="nav"
          className={css({
            gridRow: "span 2",
            minHeight: "0px",
            maxHeight: "100%",
            minWidth: "284px",
            backgroundColor: colors.gray50,
            zIndex: -1,
            position: "absolute",
            left: 0,
            top: 0,
            bottom: 0,
            paddingTop: "36px",
          })}
        >
          <Column
            className={css({
              ...expandPadding($theme.sizing.scale500),
              gap: $theme.sizing.scale0,
            })}
          >
            <TabStartEntryLink icon={<HomeIcon />}>Home</TabStartEntryLink>
            <TabStartEntryLink icon={<LogsIcon />}>Logs</TabStartEntryLink>
            <TabStartEntryLink icon={<APIIcon />}>API</TabStartEntryLink>
            <TabStartEntryLink icon={<SettingsIcon />}>Settings</TabStartEntryLink>
          </Column>
          <Divider
            $style={{
              borderTopColor: $theme.colors.alpha300,
              marginRight: "14px",
              marginLeft: "14px",
              marginBottom: "8px",
              marginTop: "-1px",
            }}
          />
          <ScrollableContainer
            role="tree"
            style={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
              paddingLeft: "8px",
            }}
          >
            <Column $as="article" $style={{ flexGrow: 1 }}>
              <NavSections />
            </Column>
          </ScrollableContainer>
          <StatusBar />
        </Column>
        <Column
          $style={{
            minWidth: 0,
            minHeight: 0,
            gridColumnStart: "2",
            backgroundColor: colors.gray50,
          }}
        >
          <WorkspaceCard $style={{ marginTop: "36px", marginLeft: "12px" }} />
        </Column>
        <Row
          $style={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: "rgba(0,0,0, 0.5)",
          }}
        />
      </article>
    </LeftNavContext.Provider>
  );
};

import { expandBorderRadii } from "@gadgetinc/widgets";
import { useStyletron } from "baseui";
import React from "react";
import type { StyleObject } from "styletron-react";

export const ColorBlob = (props: { color?: string; isDisabled?: boolean; $style?: StyleObject }) => {
  const [css, $theme] = useStyletron();

  return (
    <span
      className={css({
        display: "inline-block",
        marginRight: $theme.sizing.scale200,
        width: $theme.sizing.scale300,
        height: $theme.sizing.scale300,
        ...expandBorderRadii("50%"),
        backgroundColor: props.color ?? $theme.colors.primary300,
        ...(props.isDisabled ? {} : $theme.borders.border400),
        ...props.$style,
      })}
    />
  );
};

import { expandBorderRadii } from "@gadgetinc/widgets";
import type { Theme } from "baseui/theme";
import type { StyleObject } from "styletron-react";

export const computeFileNodeStyles = (css: (arg: StyleObject) => string, $theme: Theme, insetWidth: number) => {
  const rowWrapperWhenDragging: StyleObject = {
    height: "100%",
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
    overflow: "hidden",
    ":active": {
      opacity: 1,
    },
  };

  const rowItem: StyleObject = {
    display: "inline-block",
    overflow: "hidden",
    verticalAlign: "middle",
  };

  const label: StyleObject = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    display: "inline-block",
    textOverflow: "ellipsis",
    paddingRight: $theme.sizing.scale200,
    color: $theme.colors.linkText,
    ...$theme.typography.LabelMedium,
  };

  return {
    node: css({
      display: "flex",
      flexGrow: 1,
      height: "100%",
      width: "100%",
      overflow: "hidden",
      whiteSpace: "nowrap",
      ...expandBorderRadii($theme.borders.radius200),
      userSelect: "none",
    }),
    treeSpacing: css({
      display: "inline-block",
      minWidth: `${insetWidth}px`,
    }),
    rowWrapper: css({
      ...rowWrapperWhenDragging,
      ":hover": {
        backgroundColor: $theme.colors.backgroundQuaternary,
      },
    }),
    rowCursor: css({
      cursor: "pointer",
    }),
    rowCursorDrop: css({
      cursor: "copy",
    }),
    row: css({
      height: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      position: "relative",
      flexGrow: 1,
    }),
    rowContents: css({
      ...rowItem,
      position: "relative",
      height: "100%",
      flexGrow: 1,
      flexShrink: 1,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingLeft: "3px",
      paddingRight: "0px",
    }),
    rowIconBar: css({
      ...rowItem,
      display: "flex",
      flexGrow: 0,
      flexShrink: 0,
      alignItems: "center",
    }),
    rowToolBar: css({
      ...rowItem,
      display: "flex",
      paddingRight: $theme.sizing.scale100,
      position: "absolute",
      right: 0,
      top: 0,
      overflow: "visible",
    }),
    label: css(label),
  };
};

import { useStyletron } from "baseui";
import { StyledSvg } from "baseui/icon";
import React from "react";

export const ErrorIcon = (props: React.ComponentProps<typeof StyledSvg> & { $disabled?: boolean }) => {
  const [_css, $theme] = useStyletron();

  return (
    <StyledSvg viewBox="0 0 30 30" {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.01584 4.25998C8.31598 4.08989 8.65501 4.00033 9 4H15H21C21.3452 4.00053 21.6843 4.09039 21.9845 4.26083C22.2846 4.43128 22.5356 4.6765 22.7129 4.97266H22.7148C22.739 5.01222 22.7618 5.05261 22.7832 5.09375L22.7871 5.10352L27.6367 13.8496L27.6348 13.8516C27.8634 14.1767 28 14.5721 28 15C28 15.4279 27.8634 15.8233 27.6348 16.1484L27.6367 16.1504L22.7754 24.918C22.7599 24.9477 22.7436 24.977 22.7266 25.0059L22.7148 25.0273L22.7129 25.0293C22.5353 25.3251 22.2843 25.5699 21.9842 25.74C21.684 25.9101 21.345 25.9997 21 26H15H9C8.65483 25.9995 8.31567 25.9096 8.01551 25.7392C7.71535 25.5687 7.46442 25.3235 7.28711 25.0273H7.28516L7.25391 24.9727C7.23969 24.9476 7.22601 24.9222 7.21289 24.8965L2.36328 16.1504L2.36523 16.1484C2.13658 15.8233 2 15.4279 2 15C2 14.5721 2.13658 14.1767 2.36523 13.8516L2.36328 13.8496L7.22461 5.08203C7.24015 5.05233 7.25643 5.02302 7.27344 4.99414L7.28516 4.97266L7.28711 4.9707C7.46466 4.67491 7.7157 4.43007 8.01584 4.25998ZM19.707 19.707C20.098 19.316 20.098 18.684 19.707 18.293L16.414 15L19.707 11.707C20.098 11.316 20.098 10.684 19.707 10.293C19.316 9.90201 18.684 9.90201 18.293 10.293L15 13.586L11.707 10.293C11.316 9.90201 10.684 9.90201 10.293 10.293C9.90201 10.684 9.90201 11.316 10.293 11.707L13.586 15L10.293 18.293C9.90201 18.684 9.90201 19.316 10.293 19.707C10.488 19.902 10.744 20 11 20C11.256 20 11.512 19.902 11.707 19.707L15 16.414L18.293 19.707C18.488 19.902 18.744 20 19 20C19.256 20 19.512 19.902 19.707 19.707Z"
        fill={props.color ?? (props.$disabled ? $theme.colors.contentStateDisabled : $theme.colors.red500)}
      />
    </StyledSvg>
  );
};

import { BetaPill, Column, Row } from "@gadgetinc/widgets";
import { useStyletron } from "baseui";
import React from "react";
import { FileNode } from "./FileNode";

export const FileTree = () => {
  const [_, $theme] = useStyletron();
  return (
    <Column
      $style={{
        height: "100%",
        overflow: "hidden",
        marginLeft: $theme.sizing.scale200,
        marginRight: $theme.sizing.scale200,
      }}
    >
      <Row>
        <Column>
          <FileNode isDirectory path="/routes" label="frontend" />
        </Column>
        <Column>
          <BetaPill>Beta</BetaPill>
        </Column>
      </Row>
      <FileNode isDirectory path="/routes" label="routes" />
      <FileNode extension="html" path="/index.html" label="index.html" />
      <FileNode extension="json" path="/package.json" label="package.json" />
      <FileNode extension="js" path="/vite.config.js" label="vite.config.js" />
    </Column>
  );
};

import { useStyletron } from "baseui";
import React from "react";
import { computeFileNodeStyles } from "../../edit/workspace/LeftNavBar/files/styles";
import { FileIcon } from "./FileIcon";

export const FileNode = (props: { label: string; path: string; extension?: string; isDirectory?: boolean }) => {
  const [css, $theme] = useStyletron();
  const styles = computeFileNodeStyles(css, $theme, 0);

  return (
    <section className={`${styles.node} ${css({ height: "28px", marginLeft: $theme.sizing.scale300 })}`}>
      <div className={styles.rowWrapper}>
        <div className={styles.treeSpacing} />
        <div className={styles.row}>
          <div className={styles.rowIconBar}>
            <FileIcon extension={props.extension} path={props.path} isDirectory={props.isDirectory} />
          </div>
          <div className={styles.rowContents}>
            <div className={styles.label}>{props.label}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

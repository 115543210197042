import { omit } from "lodash";
import type { ReactNode } from "react";
import React from "react";
import type { StyleObject } from "styletron-react";
import { EntryBox } from "../../edit/workspace/LeftNavBar/EntryBox";
import { EntryLink } from "../../edit/workspace/LeftNavBar/EntryLink";

export const TabStartEntryLink = (props: { children: ReactNode; icon?: ReactNode; $style?: StyleObject; $linkStyle?: StyleObject }) => (
  <EntryBox {...omit(props, ["icon", "$linkStyle"])}>
    <EntryLink selected={false} href="#" onClick={() => undefined} $style={props.$linkStyle} icon={props.icon}>
      {props.children}
    </EntryLink>
  </EntryBox>
);

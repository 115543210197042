import { StyledSvg } from "baseui/icon";
import React from "react";

export const FolderAddIcon = (props: React.ComponentProps<typeof StyledSvg>) => (
  <StyledSvg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.02958 2.15384C2.20635 2.15384 1.53845 2.77839 1.53845 3.54819V11.4495C1.53845 12.2193 2.20635 12.8438 3.02958 12.8438H7.5V11.9143H3.02958C2.75582 11.9143 2.53253 11.7055 2.53253 11.4495V5.94253C2.53253 5.68654 2.75582 5.47775 3.02958 5.47775H12.9704C13.2442 5.47775 13.4674 5.68654 13.4674 5.94253V7.5H14.4615V4.94253C14.4615 4.17274 13.7936 3.54819 12.9704 3.54819H7.00591C7.00591 2.77839 6.33801 2.15384 5.51478 2.15384H3.02958ZM12.775 9C12.9959 9 13.175 9.17909 13.175 9.4V11.825L15.6 11.825C15.8209 11.825 16 12.0041 16 12.225V12.775C16 12.9959 15.8209 13.175 15.6 13.175H13.175V15.6C13.175 15.8209 12.9959 16 12.775 16H12.225C12.0041 16 11.825 15.8209 11.825 15.6V13.175H9.4C9.17909 13.175 9 12.9959 9 12.775V12.225C9 12.0041 9.17909 11.825 9.4 11.825H11.825V9.4C11.825 9.17909 12.0041 9 12.225 9H12.775Z"
    />
  </StyledSvg>
);

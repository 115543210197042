import { StyledSvg } from "baseui/icon";
import React from "react";

export const LogsIcon = (props: React.ComponentProps<typeof StyledSvg> & { filled: boolean }) => {
  if (props.filled) return LogsFilledIcon(props);
  return LogsOutlinedIcon(props);
};

export const LogsOutlinedIcon = (props: React.ComponentProps<typeof StyledSvg>) => (
  <StyledSvg viewBox="0 0 16 16" {...props}>
    <path d="M4.16667 2C2.97602 2 2 2.97602 2 4.16667V11.8333C2 13.024 2.97602 14 4.16667 14H11.8333C13.024 14 14 13.024 14 11.8333V4.16667C14 2.97602 13.024 2 11.8333 2H4.16667ZM4.16667 3H11.8333C12.4834 3 13 3.51665 13 4.16667V4.66667H3V4.16667C3 3.51665 3.51665 3 4.16667 3ZM3 5.66667H13V11.8333C13 12.4834 12.4834 13 11.8333 13H4.16667C3.51665 13 3 12.4834 3 11.8333V5.66667ZM5.82812 7.32812C5.72862 7.32815 5.63138 7.35786 5.54885 7.41346C5.46633 7.46906 5.40227 7.54801 5.36486 7.64022C5.32746 7.73243 5.31842 7.83371 5.3389 7.93108C5.35938 8.02846 5.40844 8.11751 5.47982 8.18685L6.45964 9.16667L5.47982 10.1465C5.43183 10.1926 5.39352 10.2477 5.36713 10.3088C5.34074 10.3699 5.3268 10.4356 5.32612 10.5021C5.32544 10.5686 5.33805 10.6346 5.36319 10.6962C5.38834 10.7578 5.42552 10.8137 5.47256 10.8608C5.51959 10.9078 5.57555 10.945 5.63713 10.9701C5.69872 10.9953 5.76471 11.0079 5.83123 11.0072C5.89775 11.0065 5.96346 10.9926 6.02453 10.9662C6.08559 10.9398 6.14078 10.9015 6.18685 10.8535L7.52018 9.52018C7.61392 9.42641 7.66657 9.29925 7.66657 9.16667C7.66657 9.03408 7.61392 8.90692 7.52018 8.81315L6.18685 7.47982C6.14024 7.43182 6.08448 7.39367 6.02286 7.36761C5.96125 7.34156 5.89503 7.32813 5.82812 7.32812ZM8.5 10C8.43374 9.99906 8.36796 10.0113 8.30648 10.036C8.24499 10.0607 8.18903 10.0974 8.14185 10.1439C8.09466 10.1904 8.0572 10.2459 8.03162 10.307C8.00605 10.3681 7.99288 10.4337 7.99288 10.5C7.99288 10.5663 8.00605 10.6319 8.03162 10.693C8.0572 10.7541 8.09466 10.8096 8.14185 10.8561C8.18903 10.9026 8.24499 10.9393 8.30648 10.964C8.36796 10.9887 8.43374 11.0009 8.5 11H10.5C10.5663 11.0009 10.632 10.9887 10.6935 10.964C10.755 10.9393 10.811 10.9026 10.8582 10.8561C10.9053 10.8096 10.9428 10.7541 10.9684 10.693C10.994 10.6319 11.0071 10.5663 11.0071 10.5C11.0071 10.4337 10.994 10.3681 10.9684 10.307C10.9428 10.2459 10.9053 10.1904 10.8582 10.1439C10.811 10.0974 10.755 10.0607 10.6935 10.036C10.632 10.0113 10.5663 9.99906 10.5 10H8.5Z" />
  </StyledSvg>
);

export const LogsFilledIcon = (props: React.ComponentProps<typeof StyledSvg>) => (
  <StyledSvg viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 4.16667C2 2.97602 2.97602 2 4.16667 2H11.8333C13.024 2 14 2.97602 14 4.16667V11.8333C14 13.024 13.024 14 11.8333 14H4.16667C2.97602 14 2 13.024 2 11.8333V4.16667ZM11.8333 3H4.16667C3.51665 3 3 3.51665 3 4.16667V4.66667H13V4.16667C13 3.51665 12.4834 3 11.8333 3ZM5.82812 7.32812C5.72861 7.32815 5.63138 7.35786 5.54885 7.41346C5.46632 7.46906 5.40226 7.54801 5.36486 7.64022C5.32746 7.73243 5.31842 7.83371 5.3389 7.93108C5.35937 8.02846 5.40844 8.11751 5.47981 8.18685L6.45963 9.16667L5.47981 10.1465C5.43183 10.1926 5.39352 10.2477 5.36713 10.3088C5.34073 10.3699 5.32679 10.4356 5.32612 10.5021C5.32544 10.5686 5.33804 10.6346 5.36319 10.6962C5.38833 10.7578 5.42551 10.8137 5.47255 10.8608C5.51959 10.9078 5.57554 10.945 5.63713 10.9701C5.69872 10.9953 5.7647 11.0079 5.83122 11.0072C5.89774 11.0065 5.96346 10.9926 6.02452 10.9662C6.08559 10.9398 6.14077 10.9015 6.18684 10.8535L7.52018 9.52018C7.61391 9.42641 7.66657 9.29925 7.66657 9.16667C7.66657 9.03408 7.61391 8.90692 7.52018 8.81315L6.18684 7.47982C6.14024 7.43182 6.08448 7.39367 6.02286 7.36761C5.96124 7.34155 5.89502 7.32813 5.82812 7.32812ZM8.5 10C8.43374 9.99906 8.36796 10.0113 8.30647 10.036C8.24499 10.0607 8.18903 10.0974 8.14184 10.1439C8.09466 10.1904 8.05719 10.2459 8.03162 10.307C8.00604 10.3681 7.99287 10.4337 7.99287 10.5C7.99287 10.5663 8.00604 10.6319 8.03162 10.693C8.05719 10.7541 8.09466 10.8096 8.14184 10.8561C8.18903 10.9026 8.24499 10.9393 8.30647 10.964C8.36796 10.9887 8.43374 11.0009 8.5 11H10.5C10.5663 11.0009 10.632 10.9887 10.6935 10.964C10.755 10.9393 10.811 10.9026 10.8581 10.8561C10.9053 10.8096 10.9428 10.7541 10.9684 10.693C10.9939 10.6319 11.0071 10.5663 11.0071 10.5C11.0071 10.4337 10.9939 10.3681 10.9684 10.307C10.9428 10.2459 10.9053 10.1904 10.8581 10.1439C10.811 10.0974 10.755 10.0607 10.6935 10.036C10.632 10.0113 10.5663 9.99906 10.5 10H8.5Z"
    />
  </StyledSvg>
);

import { IconButton, Row } from "@gadgetinc/widgets";
import { AddFileIcon } from "@gadgetinc/widgets/src/icons/AddFileIcon";
import { FolderAddIcon } from "@gadgetinc/widgets/src/icons/FolderAddIcon";
import { GlobalActionsIcon } from "@gadgetinc/widgets/src/icons/GlobalActionsIcon";
import { PlusIcon } from "@gadgetinc/widgets/src/icons/PlusIcon";
import { useStyletron } from "baseui";
import type { ComponentProps } from "react";
import React from "react";
import { NavSection } from "../../edit/workspace/LeftNavBar/NavSection";
import { FileTree } from "./FileTree";
import { Models } from "./Models";
import { TabStartEntryLink } from "./TabStartEntryLink";

export type SectionProps = Omit<ComponentProps<typeof NavSection>, "index" | "bottomIndex" | "title"> & {
  title?: ComponentProps<typeof NavSection>["title"];
};

export const NavSections = () => {
  const [_, $theme] = useStyletron();

  const sections: Record<string, SectionProps> = {
    "Data Models": {
      actions: [
        <IconButton key="add model" label="" onClick={() => undefined}>
          <PlusIcon />
        </IconButton>,
      ],
      children: (
        <>
          <Models apiIdentifier="session" />
          <TabStartEntryLink $style={{ marginTop: $theme.sizing.scale300 }}>
            <Row $gap={$theme.sizing.scale200} $style={{ width: "100%" }}>
              <GlobalActionsIcon /> Global Actions
            </Row>
          </TabStartEntryLink>
        </>
      ),
    },
    Files: {
      actions: [
        <IconButton tooltipEnabled={false} label="" key="addFile">
          <AddFileIcon />
        </IconButton>,
        <IconButton tooltipEnabled={false} label="" key="addFolder">
          <FolderAddIcon />
        </IconButton>,
      ],
      children: <FileTree />,
    },
  };

  const entries = Object.entries(sections);
  return (
    <>
      {entries.map(([sectionKey, sectionProps], sectionIndex) => (
        <NavSection
          key={sectionKey}
          index={sectionIndex}
          bottomIndex={entries.length - 1 - sectionIndex}
          title={sectionProps.title ?? sectionKey}
          {...sectionProps}
        />
      ))}
    </>
  );
};
